
























































































import { Vue, Component, Emit } from "vue-property-decorator";
import { CustomInput, MakeDuplicate, CustomTextArea } from "@/components";

interface DeliveryInformations {
  serial: number;
  branch: string;
  address: string;
  contact: string;
  instructions: string;
}
@Component({
  components: {
    CustomInput,
    MakeDuplicate,
    CustomTextArea,
  },
})
export default class DeliveryInformation extends Vue {
  componentId = 0;
  deliveryInformations: DeliveryInformations[] = [
    { serial: 0, branch: "", address: "", contact: "", instructions: "" },
  ];

  _addNewCmponent() {
    this.componentId = this.componentId + 1;
    this.deliveryInformations.unshift({
      serial: this.componentId,
      branch: "",
      address: "",
      contact: "",
      instructions: "",
    });
  }
  _removeComponent(id: number) {
    const newInformations = this.deliveryInformations.filter(
      (rep) => rep.serial != id
    );
    this.deliveryInformations = newInformations;
  }
  @Emit("getValue")
  created() {
    return 3;
  }
}
